import * as React from 'react'
import styled from 'styled-components'

import { Link as GatsbyLink } from 'gatsby'

import { colors, media, onEvent, Heading, Text } from 'cc-ui-components'

import GatsbyImage from 'gatsby-image'

export interface BlogArticleTeaserProps {
  image: any
  title: string
  category: string
  pubDate: string
  slug: string
  link?: string
}

const Link = styled(GatsbyLink)`
  text-decoration: none;
  color: inherit;

  ${onEvent`
    text-decoration: none;
  `};
`

const Card = styled.article`
  background: ${colors.inverseText};
  padding: 16px 16px 30px;
  cursor: pointer;
  box-shadow: 0 16px 24px 0 rgba(213, 213, 213, 0.5);
  transition: transform 0.5s;
  position: relative;
  &:hover {
    transform: scale(1.05);
  }
  h3 {
    margin-top: 16px;
    font-weight: 300;
  }
  h2 {
    margin-top: 8px;
  }
  ${media.sm`
    height: 358px !important;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.08);
    padding: 0 16px 30px;
    h2 {
      margin: 8px 0 12px;
      font-size: 24px;
      line-height: 30px;
    }
    h3 {
      margin-top: 16px;
    }
  `};
`

const Image = styled.div`
  height: 168px;
  ${media.sm`
    margin-left: -16px;
    margin-right: -16px;
    max-height: 150px;
    min-height: 150px;
    overflow: hidden;
  `};
`

const Img = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 168px;
  img {
    object-fit: cover;
    height: 100%;
  }
  div {
    height: 100%;
    padding-bottom: 168px !important;
  }
  ${media.sm`
    div {
      padding-bottom: 150px !important;
    }
  `};
`

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Time = styled.time`
  position: absolute;
  bottom: 10px;
  width: 100%;
  font-weight: 300;
  ${media.sm`
    font-size: 12px;
    line-height: 16px;
  `};
`

const BlogArticleTeaser: React.SFC<BlogArticleTeaserProps> = ({
  image,
  title,
  category,
  pubDate,
}) => {
  return (
    <Card>
      <Image>{image && <Img alt="" sizes={image.sizes} {...image} />}</Image>
      <Heading level="3" size="md" secondary uppercase>
        {category}
      </Heading>
      <Heading level="2" size="xl" trailer={5}>
        {title}
      </Heading>
      <Footer>
        <Text size="md" tagName="div" color={colors.lightText}>
          <Time dateTime={pubDate}>{pubDate}</Time>
        </Text>
      </Footer>
    </Card>
  )
}

const BlogArticleTeaserLink: React.SFC<BlogArticleTeaserProps> = ({
  slug,
  link,
  ...rest
}) => (
  <Link to={`${link || slug}`}>
    <BlogArticleTeaser link={link} slug={slug} {...rest} />
  </Link>
)

export default BlogArticleTeaserLink
