import * as React from 'react'
import styled, { css } from 'styled-components'

import { Row, MediumIcon, spacing, media, Heading } from 'cc-ui-components'

interface SectionHeaderProps {
  headline: string
  subline: string
  mediumIcon?: boolean
  iconColor?: string
  iconTrailer?: number
  leader?: number
  trailer?: number
}

interface WrapperProps {
  leader: number
  trailer: number
}

interface IconProps {
  trailer: number
  color?: string
}

const styles = (props: WrapperProps) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: ${spacing(props.leader)};
  margin-bottom: ${spacing(props.trailer)};
  ${media.sm`
    margin-bottom: ${spacing(props.trailer / 2)};
    h2 {
      font-size: 24px;
      line-height: 30px;
    }
    h3 {
      margin-bottom: 16px;
    }
  `};
`

const Wrapper = styled.div`
  ${styles};
`

const Icon = styled.div`
  margin-bottom: 30px;
  width: 40px;
  height: 32px;

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  ${(props: IconProps) =>
    props.color &&
    css`
      svg path {
        fill: ${props.color};
      }
    `};
`

const SectionHeader: React.SFC<SectionHeaderProps> = ({
  headline,
  subline,
  mediumIcon,
  leader = 12,
  trailer = 8,
  iconColor,
  iconTrailer = 8,
  ...rest
}) => {
  return (
    <Row>
      <Wrapper leader={leader} trailer={trailer} {...rest}>
        {mediumIcon && (
          <Icon trailer={iconTrailer} color={iconColor}>
            <MediumIcon width={'24'} height={'20'} />
          </Icon>
        )}
        <Heading level="3" size="md" trailer={1} secondary center uppercase>
          {subline}
        </Heading>
        <Heading level="2" size="xxl" center>
          {headline}
        </Heading>
      </Wrapper>
    </Row>
  )
}

export default SectionHeader
