import * as React from 'react'
import styled, { css } from 'styled-components'

import {
  Button,
  Row as RowDefault,
  Col,
  spacing,
  media,
} from 'cc-ui-components'
import BlogArticleTeaser, { BlogArticleTeaserProps } from './BlogArticleTeaser'
import { FormattedMessage } from 'react-intl'

interface BlogListProps {
  articles: BlogArticleTeaserProps[]
  leader?: number
  trailer?: number
  itemLg?: number
}

interface BlogListState {
  showMoreMobile: boolean
}

interface WrapperProps {
  leader?: number
  trailer?: number
  showMoreMobile: boolean
}

interface MobileButtonsProps {
  showMoreMobile: boolean
}

const styles = (props: WrapperProps) => css`
  margin: ${spacing(-6 + (props.leader || 0))} -10px ${spacing(props.trailer || 0)};
  ${media.sm`
    margin-left: auto;
    margin-right: auto;
    & > div:first-of-type {
      max-height: ${props.showMoreMobile ? 'unset' : '1164px'};
      overflow: hidden;
    }
  `};
`

const Wrapper = styled.div`
  ${styles};
`

const BlogItem = styled.div`
  margin-top: ${spacing(6)};

  &,
  > a {
    display: block;
    height: 100%;
  }

  > a > article {
    height: calc(100% - 50px);
  }

  ${media.sm`
    margin: ${spacing(4)} 0 0;
  `};
`

const MobileButtons = styled.div<MobileButtonsProps>`
  display: none;
  justify-content: center;
  margin-top: 34px;
  div {
    width: 100%;
    padding: 0;
  }
  ${media.sm`
    display: ${(props: MobileButtonsProps) =>
      props.showMoreMobile ? 'none' : 'flex'};
  `};
`

const RowArticle = styled(RowDefault)`
  ${media.sm`
    & > div {
      padding: 0 calc(20px - 0.66rem) !important;
    }
    padding-bottom: 30px;
  `};
`

class BlogList extends React.Component<BlogListProps, BlogListState> {
  constructor(props: BlogListProps) {
    super(props)
    this.state = { showMoreMobile: false }
  }

  public handleShoWMore = () => this.setState({ showMoreMobile: true })

  public render() {
    const { articles, leader, trailer, itemLg } = this.props
    const { showMoreMobile } = this.state
    return (
      <Wrapper
        leader={leader}
        trailer={trailer}
        showMoreMobile={showMoreMobile}
      >
        <RowArticle>
          {articles.map((article, index) => (
            <Col key={`article${index}`} sm={12} md={6} lg={itemLg || 4}>
              <BlogItem>
                <BlogArticleTeaser
                  image={article.image}
                  category={article.category}
                  title={article.title}
                  pubDate={article.pubDate}
                  slug={article.slug}
                  link={article.link}
                />
              </BlogItem>
            </Col>
          ))}
        </RowArticle>

        {articles.length > 3 && (
          <MobileButtons showMoreMobile={showMoreMobile}>
            <RowDefault>
              <Col sm={12} md={6} lg={4}>
                <FormattedMessage id={'blog.index.mobile_more'}>
                  {text => (
                    <Button
                      flat
                      medium
                      text={text.toString().toUpperCase()}
                      onClick={this.handleShoWMore}
                    />
                  )}
                </FormattedMessage>
              </Col>
            </RowDefault>
          </MobileButtons>
        )}
      </Wrapper>
    )
  }
}

export default BlogList
