import * as React from 'react'
import styled, { css } from 'styled-components'

import * as _ from 'lodash'

import Page from '../components/Page'
// import * as SegmentAnalytics from '../lib/segment'

import PopularSearchesSection from '../components/sections/PopularSearchesSection'

import BlogList from '../components/common/BlogList'
import SectionHeader from '../components/common/SectionHeader'
import {
  Center as CenterDefault,
  colors,
  spacing,
  Heading,
  Container,
  Col,
  Row,
  media,
  PageHeader,
  TwoColumn,
  Teaser,
  TeaserImage,
  CTA,
  gridLgSpacing,
} from 'cc-ui-components'
// import TeaserSlider, { SliderItem } from '../components/common/TeaserSlider'
import { BlogArticleTeaserProps } from '../components/common/BlogArticleTeaser'
import Helmet from 'react-helmet'
import Header from '../components/Header'
import { graphql } from 'gatsby'
import IndexLayout from '../components/layout'
import { RouteComponentProps, WindowLocation } from '@reach/router'
import { FormattedMessage, injectIntl } from 'react-intl'

interface BlogIndexProps {
  pageContext: {
    locale: string
  }
  data: any
}

interface SectionProps {
  light?: boolean
}

const Section = styled.section`
  padding: 0;

  ${(props: SectionProps) =>
    props.light &&
    css`
      background-color: ${colors.lightBackground};
    `};

  ${media.sm`
    & > div:first-of-type {
      padding: ${spacing(4)} 0.66rem;
    }
  `};
`

const WrapperHeader = styled.div`
  header > div {
    padding-left: 100px;
  }

  // We need to change min-height when not rendering large teaser post
  header {
    min-height: 362px !important;
    ${media.sm`
      min-height: 246px !important;
    `};
  }

  h1 {
    font-size: 40px;
    line-height: 60px;
    margin-bottom: 24px;
  }
  h2 {
    font-size: 15px !important;
    line-height: 24px !important;
    text-transform: unset;
  }
  ${media.sm`
     header > div {
      padding-left: 0;
     }
     h1 {
      font-size: 30px !important;
      line-height: 44px !important;
      margin-bottom: 16px;
      text-align: center;
    }
    h2 {
      text-align: center;
      color: ${colors.text};
      width: 60%;
      margin: auto;
    }
    header > div {
      padding-top: 64px;
    }
  `};
`

const Center = styled(CenterDefault)`
  margin-bottom: 44px;
  ${media.sm`
    & > div {
      width: 100%;
    }
  `};
`

const Spacer = styled.div`
  height: 72px;
`


// const topPostSlugs = [
//   'mobile-app-vs-mobile-website-the-pros-and-cons-for-your-business',
// ]
// const isTopPost = (slice?: number) => ({ node }: any) => {
//   return _.includes(
//     _.take(topPostSlugs, slice ? slice : topPostSlugs.length),
//     node.slug
//   )
// }

class BlogIndex extends React.Component<BlogIndexProps & RouteComponentProps> {
  /*componentDidMount(): void {
    // const title = this.props.intl.formatMessage({ id: 'blog.title' })
    const title = 'Blog - Software, HR, Tech, Future of Work | by CodeControl'
    requestAnimationFrame(() => {
      SegmentAnalytics.page(`Blog / ${title}`)
    })
  }*/

  render() {
    const { pageContext, data } = this.props
    const lang: string = pageContext.locale
    const allPosts = _.get(data, 'allContentfulBlogPost.edges')

    // const topPosts = _.filter(allPosts, isTopPost())
    // const almostAllPosts = _.filter(allPosts, _.negate(isTopPost(1)))
    //
    // const topTeasers: SliderItem[] = topPosts.map(
    //   ({ node }: any) =>
    //     ({
    //       image: node.headerImage,
    //       title: node.title,
    //       pubDate: node.updatedAt,
    //       subtitle: _.get(node, 'topic.0.title'),
    //       link: `/${lang}/blog/${node.slug}`,
    //     } as SliderItem)
    // )
    //
    // const articleTeasers = almostAllPosts.map(
    //   ({ node }: any) =>
    //     ({
    //       image: node.headerImage,
    //       category: _.get(node, 'topic.0.title'),
    //       categoryID: _.get(node, 'topic.0.id'),
    //       title: node.title,
    //       pubDate: node.updatedAt,
    //       link: `/${lang}/blog/${node.slug}`,
    //       slug: `blog/${node.slug}`,
    //     } as BlogArticleTeaserProps)
    // )
    const articleTeasers = allPosts.map(
      ({ node }: any) =>
        ({
          image: node.headerImage,
          category: _.get(node, 'topic.0.title'),
          categoryID: _.get(node, 'topic.0.id'),
          title: node.title,
          pubDate: node.publicationDate,
          link: `/${lang}/blog/${node.slug}`,
          slug: `blog/${node.slug}`,
        } as BlogArticleTeaserProps)
    )

    const title = 'Blog - Software, HR, Tech, Future of Work | by CodeControl'

    return (
      <React.Fragment>
        <Helmet htmlAttributes={{ lang }}>
          <title>{title}</title>
          <meta
            name="description"
            content="For everyone who loves tech, HR and Future of Work as much as we do. Here come our five cents�"
          />
        </Helmet>
        <Header light />
        <WrapperHeader>
          <PageHeader innerPadding={'8rem 0 0 0'} light padding={'119px 0 0'}>
            <Heading level="1" size="xxxl" trailer={1}>
              <FormattedMessage id="blog.index.headline" />
            </Heading>
            <Heading level="2" size="md" secondary uppercase>
              <FormattedMessage id="blog.index.subline" />
            </Heading>
          </PageHeader>
        </WrapperHeader>
        {/*<Section light>*/}
        {/*  <Container backgroundColor={colors.lightBackground}>*/}
        {/*    <Row>*/}
        {/*      <Col sm={12.15} md={12} lg={12.46} center>*/}
        {/*        <TeaserSlider leader={-36} trailer={-2} items={topTeasers} />*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*  </Container>*/}
        {/*</Section>*/}
        <Page>
          <Section light>
            <Container
              backgroundColor={colors.lightBackground}
            >
              <Row>
                <Col sm={12} md={12} lg={gridLgSpacing} center>
                  <Spacer />

                  <BlogList articles={articleTeasers} trailer={16} />

                  <FormattedMessage id="blog.index.medium_cta.headline">
                    {headlineTxt => (
                      <FormattedMessage id="blog.index.medium_cta.subline">
                        {sublineTxt => (
                          <SectionHeader
                            headline={headlineTxt.toString()}
                            subline={sublineTxt.toString()}
                            leader={3}
                            trailer={4}
                            mediumIcon
                          />
                        )}
                      </FormattedMessage>
                    )}
                  </FormattedMessage>

                  <Center>
                    <FormattedMessage id="blog.index.medium_cta.button">
                      {txt => (
                        <CTA
                          title={txt.toString()}
                          type={'flat'}
                          link={'https://medium.com/future-of-work'}
                        />
                      )}
                    </FormattedMessage>
                  </Center>
                </Col>
              </Row>
            </Container>
          </Section>
          <Section>
            <Container padingsVertical={[spacing(16), spacing(16)]}>
              <FormattedMessage id="blog.index.editor_section.headline">
                {headline => (
                  <FormattedMessage id="blog.index.editor_section.subline">
                    {subline => (
                      <FormattedMessage id="blog.index.editor_section.copy">
                        {copy => (
                          <FormattedMessage id="blog.index.editor_section.cta">
                            {cta => (
                              <TwoColumn
                                elements={[
                                  <TeaserImage
                                    key={'teaser-image-blog'}
                                    image={'https://imgur.com/LVJyIRM.jpg'}
                                    type={'regular'}
                                  />,
                                  <Teaser
                                    key={'teaser-blog'}
                                    title={headline.toString()}
                                    subtitle={subline.toString()}
                                    copy={copy.toString()}
                                    links={
                                      <CTA
                                        title={cta.toString()}
                                        type={'mail'}
                                        mailTo={
                                          'mailto:marketing@codecontrol.io'
                                        }
                                        white={'true'}
                                      />
                                    }
                                  />,
                                ]}
                                type={'50/50'}
                              />
                            )}
                          </FormattedMessage>
                        )}
                      </FormattedMessage>
                    )}
                  </FormattedMessage>
                )}
              </FormattedMessage>
            </Container>
          </Section>
          <PopularSearchesSection sm={12} md={12} lg={gridLgSpacing} />
        </Page>
      </React.Fragment>
    )
  }
}

const BlogIndexIntl = injectIntl(BlogIndex)

interface WrapperProps {
  location: WindowLocation | undefined
}

const BlogIndexWrapper: React.SFC<WrapperProps> = props => (
  <IndexLayout location={props.location}>
    <BlogIndexIntl {...props} />
  </IndexLayout>
)

export default BlogIndexWrapper

export const query = graphql`
  query BlogIndexQueryEn($localeRegex: String!) {
    allContentfulBlogPost(
      filter: { node_locale: { regex: $localeRegex } }
      sort: { fields: [publicationDate], order: DESC }
    ) {
      edges {
        node {
          node_locale
          title
          slug
          publicationDate(formatString: "DD MMMM YYYY")
          topic {
            id
            title
          }
          headerImage {
            fluid(maxWidth: 800, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulTopic(filter: { node_locale: { regex: $localeRegex } }) {
      edges {
        node {
          id
          title
          slug
          node_locale
        }
      }
    }
  }
`
